exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dff-registration-js": () => import("./../../../src/pages/dff-registration.js" /* webpackChunkName: "component---src-pages-dff-registration-js" */),
  "component---src-pages-dffvolunteer-js": () => import("./../../../src/pages/dffvolunteer.js" /* webpackChunkName: "component---src-pages-dffvolunteer-js" */),
  "component---src-pages-formation-js": () => import("./../../../src/pages/formation.js" /* webpackChunkName: "component---src-pages-formation-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ministry-js": () => import("./../../../src/pages/ministry.js" /* webpackChunkName: "component---src-pages-ministry-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-ocia-js": () => import("./../../../src/pages/ocia.js" /* webpackChunkName: "component---src-pages-ocia-js" */),
  "component---src-pages-ocia-registration-js": () => import("./../../../src/pages/ocia-registration.js" /* webpackChunkName: "component---src-pages-ocia-registration-js" */),
  "component---src-pages-ocia-sponsors-js": () => import("./../../../src/pages/ocia-sponsors.js" /* webpackChunkName: "component---src-pages-ocia-sponsors-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-sacraments-js": () => import("./../../../src/pages/sacraments.js" /* webpackChunkName: "component---src-pages-sacraments-js" */),
  "component---src-pages-seniorcenter-js": () => import("./../../../src/pages/seniorcenter.js" /* webpackChunkName: "component---src-pages-seniorcenter-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-yacalendar-js": () => import("./../../../src/pages/yacalendar.js" /* webpackChunkName: "component---src-pages-yacalendar-js" */),
  "component---src-pages-youngadults-js": () => import("./../../../src/pages/youngadults.js" /* webpackChunkName: "component---src-pages-youngadults-js" */)
}

